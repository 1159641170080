



































import { Component, Prop } from 'vue-property-decorator';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import TitleComponent from '@/components/TitleComponent.vue';

@Component({
  components: { InputSearchComponent, TitleComponent },
  inheritAttrs: false,
})
export default class StaticTitleWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string;

  @Prop({ required: false, default: false })
  private readonly search!: boolean;

  @Prop({ required: false, default: '' })
  private readonly placeholder!: string;

  @Prop({ required: false, default: '' })
  private readonly alignment!: string;

  @Prop({ required: false, default: '' })
  private readonly searchRoute!: string;

  created(): void {
    this.setDataConfig();
  }

  private onSearch(payload: { query: string }): void {
    if (payload.query) {
      let route = this.searchRoute;
      const matches = this.searchRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.$route.params[prop]) {
            route = route.replaceAll(m, this.$route.params[prop]);
          }
        });
      }
      if (this.searchRoute.includes('%search%')) {
        this.$router.push(route.replace('%search%', `search=${payload.query}`));
      } else {
        const { location } = this.$router.resolve(route);
        Object.assign(location.query, { search: payload.query });
        this.$router.push(location);
      }
    }
  }
}
